/* Styles applied to the root element. */
.root {
  position: relative;
  transition: margin 150ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;

  &::before {
    position: absolute;
    left: 0;
    top: -1px;
    right: 0;
    height: 1px;
    content: '';
    opacity: 1;
    background-color: rgba(0, 0, 0, 0.12);
    transition: opacity 150ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,
      background-color 150ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  }

  &:first-child {
    &::before {
      display: none;
    }
  }

  &.expanded {
    margin: 16px 0;

    &:first-child {
      margin-top: 0;
    }

    &:last-child {
      margin-bottom: 0;
    }

    &::before {
      opacity: 0;
    }
  }

  &.expanded + & {
    &::before {
      display: none;
    }
  }
}
