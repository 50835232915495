.root {
  display: table-cell;
  padding: 16px;
  border-bottom: 1px solid rgba(224, 224, 224, 1);
  vertical-align: inherit;
}

.head {
  color: rgba(0, 0, 0, 0.87);
  font-weight: 500;
  line-height: 1.5rem;
}

.body {
  color: rgba(0, 0, 0, 0.87);
}

.footer {
  color: rgba(0, 0, 0, 0.54);
  font-size: 0.75rem;
  line-height: 1.3125rem;
}

.paddingCheckbox {
  width: 48px;
  padding: 0 0 0 4px;

  &:last-child {
    padding-left: 0;
    padding-right: 4px;
  }
}

.sizeSmall {
  padding: 6px 24px 6px 16px;

  &:last-child {
    padding-right: 16px;
  }

  .paddingCheckbox {
    width: 24px;
    padding: 0 12px 0 16px;

    &:last-child {
      padding-left: 12px;
      padding-right: 16px;
    }

    & > * {
      padding: 0;
    }
  }
}

.paddingNone {
  padding: 0;

  &:last-child {
    padding: 0;
  }
}

.alignLeft {
  text-align: left;
}

.alignCenter {
  text-align: center;
}

.alignRight {
  text-align: right;
  flex-direction: row-reverse;
}

.alignJustify {
  text-align: justify;
}

.stickyHeader {
  top: 0;
  left: 0;
  z-index: 2;
  position: sticky;
  background-color: #fafafa;
}
