.root {
  color: inherit;
  display: table-row;
  outline: 0;
  vertical-align: middle;

  &.hover:hover {
    background-color: rgba(0, 0, 0, 0.04);
  }
}
