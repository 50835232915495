/* Styles applied to the container element. */
.container {
  height: 0;
  transition: height 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
}

/* Styles applied to the outer wrapper element. */
.wrapper {
  // Hack to get children with a negative margin to not falsify the height computation.
  display: flex;
}

/* Styles applied to the inner wrapper element. */
.wrapperInner {
  width: 100%;
}
