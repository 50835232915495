@import '../../../theme/newVariables.scss';

.container {
  background-color: $az-grey-1;
  padding: $az-spacing-m $az-spacing-xs $az-spacing-l;

  @media #{$small-and-down} {
    padding-bottom: $az-spacing-m;
  }
}

.whiteTheme {
  background-color: $az-white;
}

.pageTitle {
  font-family: $font-az-cond;
  font-size: $font-size-lg;
  letter-spacing: 0.02rem;
  line-height: 1.12;
  text-transform: uppercase;

  strong {
    font-family: $font-az-cond-heavy;
  }
}

.separator {
  width: $az-spacing-84;
  height: $az-spacing-5xs;
  background-color: $az-medium-orange;
  transform: skewX(-30deg);
}

.shopAllContainer {
  text-align: right;
  padding-bottom: $az-spacing-m;
}

.gridContainer {
  border: solid $az-grey-2;
  border-width: $az-spacing-1 $az-spacing-zero $az-spacing-zero $az-spacing-1;
  margin-bottom: $az-spacing-4xs;
  margin-top: 32px;
  @media #{$medium-and-down} {
    margin-top: 20px;
  }

  @media #{$extra-large-and-down} {
    margin-bottom: $az-spacing-zero;
  }
}

.gridItem {
  display: flex;
  justify-content: center;
  align-content: center;
  height: $az-spacing-200;
  background-color: $az-white;
  border: solid $az-grey-2;
  border-width: $az-spacing-zero $az-spacing-1 $az-spacing-1 $az-spacing-zero;
  text-align: $cdirection;
  transition: box-shadow 250ms, transform 250ms;

  .link {
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    align-items: center;
  }

  &:hover {
    box-shadow: $az-spacing-zero $az-spacing-2 $az-spacing-5 $az-spacing-zero rgba(0, 0, 0, 0.07),
      $az-spacing-zero $az-spacing-5xs $az-spacing-5 $az-spacing-zero rgba(0, 0, 0, 0.06),
      $az-spacing-zero $az-spacing-1 $az-spacing-6 $az-spacing-zero rgba(0, 0, 0, 0.1);
    transform: scale(1.06);
  }

  img {
    width: $az-spacing-100;
    height: $az-spacing-100;
  }

  .gridItemText {
    text-align: center;
    color: $az-black;

    @media #{$small-and-down} {
      margin-right: $az-spacing-4xs;
      margin-left: $az-spacing-4xs;
    }
  }
}
