@import '@/theme/newVariables.scss';

.productCardLink {
  text-decoration: none;
  color: inherit;
  display: flex;
  flex: 1;
  flex-direction: column;

  @media #{$medium-and-up} {
    p {
      flex: 1;
    }
  }
}

.productCardContent {
  margin: 0 auto;
  max-width: $az-spacing-376;
  font-size: $az-spacing-14;
  padding: var(--st-unit-4);
  display: flex;
  flex: 1;
  flex-direction: column;

  .title {
    font-family: $font-az-helvetica-heavy;
    font-weight: 500;
    font-size: $font-size-18;
    margin-top: $az-spacing-xxs;
    margin-bottom: $az-spacing-4xs;
  }
  p {
    margin-bottom: $az-spacing-xxs;
  }

  .image {
    max-width: 100%;
    width: auto;
  }
}

.contentCenterAligned {
  text-align: center;
  .warranty {
    display: flex;
    align-items: center;
    justify-content: center;
  }
}

.contentLeftAligned {
  text-align: left;
  .warranty {
    display: flex;
    align-items: center;
    justify-content: flex-start;
  }
}
