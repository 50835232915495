/* Styles applied to the root element. */
.root {
  display: flex;
  min-height: 48px;
  transition: min-height 150ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,
    background-color 150ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  padding: 0 16px;

  &:hover:not(.disabled) {
    cursor: pointer;
  }

  &.expanded {
    min-height: 64px;
  }

  &.focused {
    background-color: rgba(0, 0, 0, 0.12);
  }

  &.disabled {
    opacity: 0.38;
  }
}

/* Styles applied to the children wrapper element. */
.content {
  display: flex;
  flex-grow: 1;
  transition: margin 150ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  margin: 16px 0;
}

/* Styles applied to the `IconButton` component when `expandIcon` is supplied. */
.expandIcon {
  transform: rotate(0deg);
  transition: transform 150ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;

  &:hover,
  &:focus {
    // Disable the hover effect for the IconButton,
    // because a hover effect should apply to the entire Expand button and
    // not only to the IconButton.
    background-color: transparent;
  }

  &.expanded {
    transform: rotate(180deg);
  }
}
