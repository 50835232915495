@import '../../../theme/newVariables';

.expansionContainer {
  max-width: $az-percent-100;

  .grey {
    background-color: $az-grey-1;
  }

  .white {
    background-color: $az-white;
  }
  .expansionSummary {
    display: flex !important;
    padding: $az-spacing-zero $az-spacing-xxs;
    border-bottom: $az-spacing-1 solid $az-grey-2;
    align-items: flex-start;
    outline: 0;

    @media #{$large-and-up} {
      &:focus-visible {
        outline: 2px solid #015fcc;
      }
    }
  }

  .expansionSummarySignInV2 {
    display: flex !important;
    padding: $az-spacing-zero $az-spacing-xs;
    border-bottom: $az-spacing-1 solid $az-grey-2;
    align-items: flex-start;
    outline: 0;

    @media #{$large-and-up} {
      &:focus-visible {
        outline: 2px solid #015fcc;
      }
    }
  }

  .expanded {
    border-bottom: none !important;
  }
}

.arrowExpand {
  height: 1em;
  width: 1em;
  display: flex;
  align-items: center;
  justify-content: center;
}

.accordionCurvedBorder {
  // BMF-5383: Elevation shadow is removed as per Comments from Robert Pugh.
  box-shadow: none !important;

  @media #{$small-and-down} {
    border-radius: $az-spacing-5xs;
  }
}

.root {
  color: $az-grey-4;
}

.expanded {
  margin: auto;
  color: $az-black;
}
