@import '../../../theme/newVariables.scss';

.toolTipContainer {
  display: inline-block;
  position: relative;
  padding-left: $az-spacing-3;
  vertical-align: middle;
  line-height: 1;
}

.toolTipPopperContainer {
  opacity: 100%;
}

@media #{$medium-and-down} {
  .toolTipPopperPaper {
    width: auto !important;
  }
}

.toolTipPopperTitle {
  display: flex;
  justify-content: space-between;
  width: 100%;
}

.toolTipPopperTitle .toolTipPopperHeading {
  display: flex;
}

.toolTipIcon {
  display: inline-flex;
  padding: $az-spacing-zero;
  width: $az-spacing-18;
  height: $az-spacing-18;
}

.tooltipImage {
  width: 100%;
  height: auto;
}

.popperPadding {
  padding: $az-spacing-zero $az-spacing-10;
}

.hasIconSizePxOverride {
  margin: 0px !important;
  padding: 0px !important;
}
