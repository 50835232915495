.root {
  display: table;
  width: 100%;
  border-collapse: collapse;
  border-spacing: 0;
}

.stickyHeader {
  border-collapse: separate;
}
